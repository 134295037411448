import React from "react";
import Helmet from 'react-helmet';
import Layout from '../components/layout';
import favicon from '../images/favicon.ico';
import { InPageBot } from "@leadoo/react-bot";

const BOT_CODE = "fY9CQ1aQ";

function Demo(){
    return(
        <Layout>
            <Helmet>
                <title>Boka demo | Optosweden</title>
                <link rel="icon" type="image/png" href={favicon} sizes="16x16" />
                <meta name="description" content="vi har kunskapen och kompetensen att optimera er dokumenthantering. Kom i kontakt med oss för mer information." />
                <meta name="keywords" content="tidsbokning | demo | kontakta" />
                <meta property="og:title" content="Optosweden AB" />
                <meta property="og:tyope" content="website" />
                <meta property="og:discription" content="" />
                <meta property="og:image" content=" " />
                <meta property="og:locale" content="sv_SE" />
                <meta property="og:url" content="https://www.optosweden.se/demo" />
                <link rel="cannonical" href="https://www.optosweden.se/demo" />
            </Helmet>


            <section className="border-t border-gray-100 pt-28">
                    <h1 className="text-center title-font font-semibold text-3xl text-blue-custome">Boka Demo</h1>
            </section>

            <section className="py-20">
                <div className="container mx-auto px-4 xl:px-24">
                    <div className="w-full lg:w-9/12 xl:w-8/12 2xl:w-7/12" style={{minHeight: "300px"}}>
                        <InPageBot code={BOT_CODE} seamless/>
                    </div>
                    <div className="-mt-16 md:-mt-32 lg:-mt-60 xl:-mt-96 bg-support-girl bg-auto bg-right-top bg-no-repeat md:pt-40 pb-96"></div>
                </div>
            </section>
        </Layout>
    );
}
export default Demo;